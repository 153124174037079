import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Reception } from 'appro/models/dto/reception';
import { Expedition } from 'tracabilite/models/dto/expedition';
import { IRequestOptions } from 'global/interfaces/IRequestOptions';
import { Tiers } from '../../../tracabilite/models/dto/tiers';
import { Parcelle } from '../../../tracabilite/modules/epandages/models/dto/parcelle';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {

	constructor(
		private httpClient: HttpClient
	) { }

	/**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
	/**
	* Uplooad attachements
	* @param path - url path to upload attachments
	* @param files - files to upload
	* @param reception - reception id
	* @param organisation - organisation id
	*/
	private uploadDocuments(path: string, files: any[], docId: string, organisation: string): Observable<any> {
		let formData: FormData = new FormData();
		let attachments = [];
		for (let i = 0; i < files.length; i++) {
			if (files[i]._id == undefined) {
				formData.append(
					`files`,
					files[i].file,
					files[i].name
				);

				attachments.push({
					name: files[i].name,
					documentType: files[i].documentType,
					size: files[i].size,
					expirationDate: files[i].expirationDate,
					emissionDate: files[i].emissionDate
				})
			}
		}
		formData.append(`attachments`, new Blob([JSON.stringify(attachments)], { 'type': 'application/json' }));
		const httpOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*'
			})
		};
		const req = new HttpRequest('POST', environment.api + path, formData, httpOptions);
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('document'))
			)
	}

	public getDocument(organisation: string, document: string) {
		let path: string = `/organisations/${organisation}/document/${document}`;
		const req = new HttpRequest('GET', environment.api + path, null, {
			responseType: 'blob'
		});
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('reception-document'))
			)
	}

	public get environment() {
		return environment;
	}

	/**
	* Uplooad files
	* @param files - files to upload
	*/
	public uploadAnalyseLaboDocuments(files: any[], analyse: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/analyseLabo/${analyse}/documents`;

		let formData: FormData = new FormData();
		for (let i = 0; i < files.length; i++) {
			if (files[i]._id == undefined)
				formData.append(
					`files`,
					files[i].file,
					files[i].name
				);
		}

		const req = new HttpRequest('POST', environment.api + path, formData, {
			reportProgress: false // for progress data,
		});

		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('document'))
			)
	}

	public sendFactureByMail(organisation: string, factures: any[]): Observable<any> {
		let path: string = `/organisations/${organisation}/facture/sendByMail`;
		let helps = [];
		helps = factures.map(facture => {
			let help: any = Object.assign({}, facture);
			help.id = help._id;
			help.revision = help._rev;
			help.tiers.id = help.tiers._id;
			help.contrat.site = undefined;
			if (help.site && help.site != null && help.site._id) {
				help.site.organisation = undefined;
				help.site.id = help.site._id;
			}
			if (help.tiers.site && help.tiers.site != null && help.tiers.site._id)
				help.tiers.site = help.tiers.site._id;
			return help;
		})
		return this.httpClient.post<any>(environment.api + path, helps);
	}

	public sendReceptionByMail(organisation: string, documentDestinataires: any[]): Observable<any> {
		let path: string = `/organisations/${organisation}/receptions/sendByMail`;
		return this.httpClient.post<any>(environment.api + path, documentDestinataires);
	}

	public sendExpeditionByMail(organisation: string, documentDestinataires: any[]): Observable<any> {
		let path: string = `/organisations/${organisation}/expeditions/sendByMail`;
		return this.httpClient.post<any>(environment.api + path, documentDestinataires);
	}

	public printMultipleFacturePdf(organisation: string, factures: any[]): Observable<any> {
		let path: string = `/organisations/${organisation}/factures/printPdf`;
		let helps = [];
		helps = factures.map(facture => {
			let help: any = Object.assign({}, facture);
			help.id = help._id;
			help.revision = help._rev;
			help.tiers.id = help.tiers._id;
			help.contrat.site = undefined;
			if (help.site && help.site != null && help.site._id) {
				help.site.organisation = undefined;
				help.site.id = help.site._id;
			}
			if (help.tiers.site && help.tiers.site != null && help.tiers.site._id)
				help.tiers.site = help.tiers.site._id;
			return help;
		})
		const req = new HttpRequest('POST', environment.api + path, helps, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('print-multiple-facture'))
			)
	}

	public generateMultipleFacturePdf(organisation: string, factures: any[]): Observable<any> {
		let path: string = `/organisations/${organisation}/factures/generatePdf`;
		let helps = [];
		helps = factures.map(facture => {
			let help: any = Object.assign({}, facture);
			help.id = help._id;
			help.revision = help._rev;
			help.tiers.id = help.tiers._id;
			help.contrat.site = undefined;
			if (help.receptions)
				help.receptions = help.receptions.map(recep => {
					recep.id = recep._id;
					if (recep.gisement && recep.gisement.stockIntrant) delete recep.gisement.stockIntrant.site;
					return recep;
				})
			if (help.site && help.site != null && help.site._id) {
				help.site.organisation = undefined;
				help.site.id = help.site._id;
			}
			if (help.contrat.site && help.contrat.site.organisation)
				delete help.contrat.site.organisation;
			if (help.tiers.site && help.tiers.site != null && help.tiers.site._id)
				help.tiers.site = help.tiers.site._id;
			return help;
		})
		return this.httpClient.post<any>(environment.api + path, helps)
			.pipe(
				catchError(this.handleError<any>('generateFacturePdfs'))
			)
	}

	public generateSingleFacturePdf(organisation: string, facture: any): Observable<any> {
		let path: string = `/organisations/${organisation}/facture/generatePdf`;
		let help: any = Object.assign({}, facture);
		help.id = help._id;
		help.revision = help._rev;
		help.site.id = help.site._id;
		help.tiers.id = help.tiers._id;
		help.contrat.site = undefined;
		if (help.receptions)
			help.receptions = help.receptions.map(recep => {
				recep.id = recep._id;
				if (recep.gisement && recep.gisement.stockIntrant) delete recep.gisement.stockIntrant.site;
				return recep;
			})
		if (help.site && help.site != null && help.site._id) {
			help.site.organisation = undefined;
			help.site.id = help.site._id;
		}
		if (help.contrat.site && help.contrat.site.organisation)
			delete help.contrat.site.organisation;
		if (help.tiers.site && help.tiers.site != null && help.tiers.site._id)
			help.tiers.site = help.tiers.site._id;
		return this.httpClient.post<any>(environment.api + path, help)
			.pipe(
				catchError(this.handleError<any>('generateFacturePdf'))
			)
	}
	/**
	* Uplooad files
	* @param files - files to upload
	*/
	public uploadGisementDocuments(files: any[], gisement: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/gisement/${gisement}/documents`;
		return this.uploadDocuments(path, files, gisement, organisation);
	}

	public generateTiersDeclaration(tiers: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/tiers/${tiers}/declaration`;
		const req = new HttpRequest('GET', environment.api + path, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('get-tiers-declaration'))
			)

	}

	public generatePOS(organisation: string, site: string): Observable<any> {

		let path: string = `/organisations/${organisation}/sites/${site}/red2/pos`;
		const req = new HttpRequest('GET', environment.api + path, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('get-red2-pos'))
			)

	}

	public generateGisementDeclaration(gisement: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/gisement/${gisement}/declaration`;
		const req = new HttpRequest('GET', environment.api + path, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('get-gisement-declaration'))
			)

	}

	public generateGisementFIPA(gisement: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/gisement/${gisement}/fipa`;
		const req = new HttpRequest('GET', environment.api + path, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('get-gisement-fipa'))
			)

	}

	public generateParcelleDeclaration(gisement: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/parcelle/${gisement}/declaration`;
		const req = new HttpRequest('GET', environment.api + path, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('get-parcelle-declaration'))
			)

	}

	/**
	* Upload reception attachements
	* @param files - files to upload
	* @param reception - reception id
	* @param organisation - organisation id
	*/
	public uploadContratDocument(files: any[], contrat: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/contrats/${contrat}/documents`;
		return this.uploadDocuments(path, files, contrat, organisation);
	}

	/**
	* Upload reception attachements
	* @param files - files to upload
	* @param reception - reception id
	* @param organisation - organisation id
	*/
	public uploadReceptionDocuments(files: any[], reception: string, organisation: string): Observable<any> {

		let path: string = `/organisations/${organisation}/receptions/${reception}/documents`;
		return this.uploadDocuments(path, files, reception, organisation);
	}

	/**
	 * Upload tiers attachements
	 * @param files 
	 * @param tiers 
	 * @param organisation 
	 */
	public uploadTiersDocuments(files: any[], tiers: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/tiers/${tiers}/documents`;
		return this.uploadDocuments(path, files, tiers, organisation);
	}

	/**
	 * Upload evenement attachements
	 * @param files 
	 * @param evenement 
	 * @param organisation 
	 */
	public uploadEvenementDocuments(files: any[], evenement: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/evenements/${evenement}/documents`;
		return this.uploadDocuments(path, files, evenement, organisation);
	}

	/**
	 * Upload fiches interventions attachements
	 * @param files 
	 * @param ficheIntervention 
	 * @param organisation 
	 */
	public uploadInterventionsDocuments(files: any[], ficheIntervention: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/fichesIntervention/${ficheIntervention}/documents`;
		return this.uploadDocuments(path, files, ficheIntervention, organisation);
	}

	/**
	 * Upload tache attachements
	 * @param files 
	 * @param tache 
	 * @param organisation 
	 */
	public uploadTacheDocuments(files: any[], tache: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/taches/${tache}/documents`;
		return this.uploadDocuments(path, files, tache, organisation);
	}

	/**
	 * Upload equipement attachements
	 * @param files 
	 * @param equipement 
	 * @param organisation 
	 */
	public uploadEquipementDocuments(files: any[], equipement: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/equipements/${equipement}/documents`;
		return this.uploadDocuments(path, files, equipement, organisation);
	}

	/**
	 * Upload piece attachements
	 * @param files 
	 * @param piece 
	 * @param organisation 
	 */
	public uploadPieceDocuments(files: any[], piece: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/pieces/${piece}/documents`;
		return this.uploadDocuments(path, files, piece, organisation);
	}

	/**
	 * Upload refFournisseur attachements
	 * @param files 
	 * @param refFournisseur
	 * @param organisation 
	 */
	public uploadRefFournisseurDocuments(files: any[], refFournisseur: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/refFournisseurs/${refFournisseur}/documents`;
		return this.uploadDocuments(path, files, refFournisseur, organisation);
	}

	/**
	 * Upload parcelle attachements
	 * @param files 
	 * @param parcelle 
	 * @param organisation 
	 */
	public uploadParcelleDocuments(files: any[], parcelle: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/parcelles/${parcelle}/documents`;
		return this.uploadDocuments(path, files, parcelle, organisation);
	}
	/**
	* Upload expedition attachments
	* @param files - files to upload
	* @param reception - reception id
	* @param organisation - organisation id
	*/
	public uploadExpeditionDocuments(files: any[], expedition: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/expeditions/${expedition}/documents`;
		return this.uploadDocuments(path, files, expedition, organisation);
	}
	/**
	* Upload ticket attachements
	* @param files - files to upload
	* @param ticket - ticket id
	* @param organisation - organisation id
	*/
	public uploadTicketDocument(files: any[], ticket: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/ticket/${ticket}/documents`;
		return this.uploadDocuments(path, files, ticket, organisation);
	}
	/**
	* Upload intervention attachements
	* @param files - files to upload
	* @param intervention - intervention id
	* @param organisation - organisation id
	*/
	public uploadInterventionDocument(files: any[], intervention: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/intervention/${intervention}/documents`;
		return this.uploadDocuments(path, files, intervention, organisation);
	}
	/**
	* Upload contrat de maintenance attachements
	* @param files - files to upload
	* @param contratMaintenance - contrat de maintenance id
	* @param organisation - organisation id
	*/
	public uploadContratMaintenanceDocument(files: any[], contratMaintenance: string, organisation: string): Observable<any> {
		let path: string = `/organisations/${organisation}/contratMaintenance/${contratMaintenance}/documents`;
		return this.uploadDocuments(path, files, contratMaintenance, organisation);
	}
	/**
	* Delete  a document
	* @param organisation - organisation id
	* @param document - document id
	*/
	public deleteDocument(organisation: string, document: string) {
		let path: string = `/organisations/${organisation}/document/${document}`;
		return this.httpClient.delete<any>(environment.api + path)
			.pipe(
				catchError(this.handleError<any>('document-delete'))
			)
	}
	/**
	* Delete linked documents
	* @param organisation - organisation id
	* @param document - linked document id
	*/
	public deleteDocuments(organisation: string, document: string) {
		let path: string = `/organisations/${organisation}/documents/${document}`;
		return this.httpClient.delete<any>(environment.api + path)
			.pipe(
				catchError(this.handleError<any>('document-delete'))
			)
	}
	/**
	* Generate recpetion document
	* @param organisation - organisation id
	* @param reception - recpetion
	*/
	public generateReceptionDocument(organisation: string, reception: Reception) {
		let help: any = Object.assign({}, reception);
		help.id = help._id;
		help.revision = help._rev;
		if (help.site && help.site.organisation)
			delete help.site.organisation;
		help.site.id = help.site._id || help.site.id;
		if (help.gisement.ouvrageIncorporation && help.gisement.ouvrageIncorporation.site && help.gisement.ouvrageIncorporation.site._id) help.gisement.ouvrageIncorporation.site = help.gisement.ouvrageIncorporation.site._id;
		if (help.gisement.stockIntrant && help.gisement.stockIntrant.site && help.gisement.stockIntrant.site._id) help.gisement.stockIntrant.site = help.gisement.stockIntrant.site._id;
		let path: string = `/organisations/${organisation}/receptions/generateDocument`;
		const req = new HttpRequest('POST', environment.api + path, help, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('reception-document'))
			)
	}/**
	 * Generate tiers document
	 * @param organisation 
	 * @param tiers 
	 */
	public generateTiersDocument(organisation: string, tiers: Tiers) {
		let help: any = Object.assign({}, tiers);
		help.id = help._id;
		help.revision = help._rev;
		if (help.site && help.site.organisation)
			delete help.site.organisation;
		help.site.id = help.site._id || help.site.id;
		let path: string = `/organisations/${organisation}/tiers/generateDocument`;
		const req = new HttpRequest('POST', environment.api + path, help, {
			responseType: 'blob'
		});
		const option: IRequestOptions = {
			responseType: 'blob'
		}

		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('tiers-document'))
			)
	}/**
	 * Generate parcelle document
	 * @param organisation 
	 * @param parcelle 
	 */
	public generateParcelleDocument(organisation: string, parcelle: Parcelle) {
		let help: any = Object.assign({}, parcelle);
		help.id = help._id;
		help.revision = help._rev;
		if (help.site && help.site.organisation)
			delete help.site.organisation;
		help.site.id = help.site._id || help.site.id;
		let path: string = `/organisations/${organisation}/parcelle/generateDocument`;
		const req = new HttpRequest('POST', environment.api + path, help, {
			responseType: 'blob'
		});
		const option: IRequestOptions = {
			responseType: 'blob'
		}

		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('parcelle-document'))
			)
	}
	/**
	 * Generate stockDigestat document
	 * @param organisation
	 * @param stockDigestat
	 * @param expedition
	 * */
	public generateStockDigestatDocument(organisation: string, stockId: string, expeditionId: string) {
		const path = `/organisations/${organisation}/stockDigestat/${stockId}/${expeditionId}/generateDocument`;
		const req = new HttpRequest('GET', environment.api + path, {
			responseType: 'blob'
		});
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('stock-digestat-document'))
			)
	}
	/**
	 * 
	 * @param organisation
	 * @param expedition
	 */
	public generateReceptionTicketDocument(organisation: string, reception: Reception) {
		let help: any = Object.assign({}, reception);
		help.id = help._id;
		help.revision = help._rev;
		if (help.site && help.site.organisation)
			delete help.site.organisation;
		help.site.id = help.site._id || help.site.id;
		if (help.gisement.stockIntrant.site && help.gisement.stockIntrant.site._id) help.gisement.stockIntrant.site = help.gisement.stockIntrant.site._id;
		let path: string = `/organisations/${organisation}/receptions/generateDocument`;
		const req = new HttpRequest('POST', environment.api + path, help, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('ticket-document'))
			)
	}
	/**
	* Generate expedition document
	* @param organisation - organisation id
	* @param expedition - expedition
	*/
	public generateExpeditionDocument(organisation: string, expedition: Expedition) {
		let help: any = Object.assign({}, expedition);
		help.id = help._id;
		help.revision = help._rev;
		help.site.organisation = undefined;
		help.site.id = help.site._id || help.site.id;
		let path: string = `/organisations/${organisation}/expeditions/generateDocument`;
		const req = new HttpRequest('POST', environment.api + path, help, {
			responseType: 'blob'
		});
		const options: IRequestOptions = {
			responseType: 'blob'
		}
		/// TS_IGNORE
		return this.httpClient.request(req)
			.pipe(
				catchError(this.handleError<any>('expedition-document'))
			)
	}

	public showPdf(pdfData: Blob, wr: string = "pdf-wrapper") {
		let reader = new FileReader();
		reader.readAsDataURL(pdfData);
		let wrapper = document.getElementById(wr);
		wrapper.innerHTML = '';
		reader.onloadend = function () {
			let pdfjsLib = window['pdfjsLib'];
			pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.189/pdf.worker.mjs';
			let base64Data = reader.result as string;
			let loadingTask = pdfjsLib.getDocument({ data: atob(base64Data.split(',')[1]) as string });
			loadingTask.promise.then(function (pdf) {
				// Fetch pdf pages
				for (let i = 1; i <= pdf.numPages; i++) {
					pdf.getPage(i).then(function (page) {
						let scale = 1.5;
						let viewport = page.getViewport({ scale: scale });
						// Prepare canvas using PDF page dimensions
						let canvas = <HTMLCanvasElement>document.createElement("canvas");
						canvas.style.display = "block";
						canvas.style.margin = "auto";
						let context = canvas.getContext('2d');
						canvas.height = viewport.height;
						canvas.width = viewport.width;
						wrapper.appendChild(canvas);
						// Render PDF page into canvas context
						let renderContext = {
							canvasContext: context,
							viewport: viewport
						};
						let renderTask = page.render(renderContext);
						renderTask.promise.then(function () {
							//if(i == pdf.numPages )
						});

					});
				}
			}, function (reason) {
				// PDF loading error
				console.error(reason);
			});
		}
	}
}
