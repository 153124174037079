import { SiteDocumentDAO } from 'global/models/dao/site-document';
import { CaracteristiquePhysicoChimique } from './../caracteristique-physico-chimique';

export class GisementDAO extends SiteDocumentDAO{

  public codeGisement  : string;
  public denominationProduit : string;
  public nom  : string;
  public type : string;
  public categorie  : string;
  public categorieRed2  : string;
  public codeDechet  : string;
  public consistance  : string;
  public conditionnement : string;
  public modeDeLivraison  : string;
  public sousProduitsAnimaux  : string;
  public documentAEmettre : string;
  public tags  : string[];
  public stockIntrant : string;
  public ouvrageIncorporation: string;
  public digesteur: string;
  public producteur : string;
  public transporteur : string;
  public negociant : string;
  public codeContrat : string;
  public cpc : CaracteristiquePhysicoChimique[] ;
  public analyses : string[];
  public parcelles: string[];
  public dateDerniereAnalyse : any;
  public numCAP: string;
  public typeFacturation : string[];
  public notDisplayInDropdowns : boolean = false;
  public distanceSiteProduction: number;
  public commentaires : string;
  public delaiIncorporation: number;
  public numLot: string;
  public traitementSubi: string;
  public traitementAEffectuer: string;
  public famille: string;
  public familleFGR: string;
  public durable: boolean = false;
  public gesProduction: number;
  public gesTransport: number;
  public tauxMatiereDurable: number;
  public retourAVide: boolean;
  public schemaVolontaire: string;
  public carburantParDefaut: string;
  public referenceContrat: string;

  constructor(){
  	super();
  	this.dataType = 'gisement';
  }
}
