import { CaracteristiqueEconomique } from '../../../../models/caracteristique-economique';
import { SiteDocument } from 'global/models/dto/site-document';
import { CaracteristiquePhysicoChimique } from 'tracabilite/models/caracteristique-physico-chimique';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { StockIntrantDAO } from '../dao/stock-intrant';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import * as _ from 'lodash';

export class StockIntrant extends SiteDocument implements IDto2Dao, IBGVTreeNode {
	public categorie: string;
	public categorieRed2  : string;
	public famille: string;
	public nom: string;
	public code: string;
	public lieuStockage: string;
	public quantiteIncorporeeCste: boolean;
	public calculTonnage: boolean;
	public calculCPC: boolean;
	public consigneCste: boolean;
	public cpc: CaracteristiquePhysicoChimique[];
	public ce: CaracteristiqueEconomique[];
	public tonnage: number;
	public dateTonnageRef: any;
	public tags: string[];
	public etatStock: number;
	public notDisplayInDropdowns: boolean = false;
	public methodCalculCPC: string;
	public tauxMatiereDurable: number;
	public tempsSejours: number;
	public esca: number;
	public etdfeeds: number;
	public ei: number;
	public eec: number;
	public faibleRisqueILUC: boolean;
	public cive: boolean;
	public methodCalculDurabilite: string;
	public paysDorigine: string;

	constructor() {
		super();
		this.dataType = 'stockIntrant';
		this.quantiteIncorporeeCste = false;
		this.calculTonnage = true;
		this.calculCPC = true;
		this.consigneCste = true;
		this.tonnage = 0;
		this.methodCalculCPC = 'Utiliser le dernier gisement';
		this.dateTonnageRef = this.lastDay();
		this.cive = false;
		this.faibleRisqueILUC = false;
		//this.methodCalculCPC = "Utiliser le dernier gisement";
	}

	public toDao(): StockIntrantDAO {
		let stock: StockIntrantDAO = new StockIntrantDAO();
		Object.keys(this)
			.forEach(key => {
				if (key == "site") stock[key] = this[key]._id || this[key].id;
				else if (key == 'tonnage') stock[key] = +this[key];
				else if (key == 'dateTonnageRef') {
					let date = new Date(this[key]);
					stock[key] = date.getTime();
				} else {
					stock[key] = this[key];
				}
			});
		return stock;
	}

	toNodes(parentId?: number, parentName?: any): any {
		let keyNames = [
			{ 'label': 'app.global.headers.nom', 'dataKey': 'nom' },
			{ 'label': 'app.global.headers.code', 'dataKey': 'code' },
			{ 'label': 'app.global.headers.site', 'dataKey': 'site.nom' },
			{ 'label': 'app.global.headers.categorie', 'dataKey': 'categorie' },
			{ 'label': 'app.global.headers.lieuStockage', 'dataKey': 'lieuStockage' },
			{ 'label': 'app.global.headers.tonnage', 'dataKey': 'tonnageCalculer' },
			{ 'label': 'app.global.headers.dateTonnageReference', 'dataKey': 'dateTonnageRef' },
			{ 'label': 'app.global.headers.desactive', 'dataKey': 'notDisplayInDropdowns' },
			{'label': 'Matière organique sur MB', 'dataKey': 'MO/MB'},
			{'label': 'Matière sèche sur MB', 'dataKey': 'MS/MB'},
			{'label': 'Potentiel méthane sur MB', 'dataKey': 'PM/MB stand'},
			{'label': 'Azote total', 'dataKey': 'Ntot'},
			{'label': 'Phosphore (P2O5)', 'dataKey': 'P2O5'},
			{'label': 'Azote', 'dataKey': 'N'},
			{'label': 'Potassium (K2O)', 'dataKey': 'K2O'},
			{'label': 'Soufre (SO3)', 'dataKey': 'SO3'},
			{'label': 'Densité', 'dataKey': 'Densite'},
			{'label': 'pH', 'dataKey': 'pH'},
			{'label': 'Azote ammoniacal (N-NH4)', 'dataKey': 'N-NH4'},
			{'label': 'Catégorie RED II', 'dataKey': 'categorieRed2'},
			{'label': 'Famille RED II', 'dataKey': 'famille'},
			{'label': 'Taux de matière durable', 'dataKey': 'tauxMatiereDurable'},
			{'label': 'Tonnage de référence', 'dataKey': 'tonnage'},
			{'label': 'Temps de stockage moyen', 'dataKey': 'tempsSejours'},
			{'label': 'Esca (g CO2eq/MJ)', 'dataKey': 'esca'},
			{'label': 'Etd feeds (g CO2eq/MJ)', 'dataKey': 'etdfeeds'},
			{'label': 'EI (g COSeq/MJ)', 'dataKey': 'ei'},
			{'label': 'Eec (g COSeq/MJ)', 'dataKey': 'eec'},
		]
		let index = 0;
		return keyNames.map(key => {
			let node: any = {},
				id = parentId ? parentId + index + 1 : index;
			node = {
				id: id,
				name: key.label,
				dataKey: key.dataKey
			}
			index++;
			return node;
		})
	}

	private lastDay(): Date {
		let todayTime = new Date().getTime();
		return new Date(todayTime - 24 * 60 * 60 * 1000);
	}

}
