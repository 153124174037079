import { Injectable } from '@angular/core';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { DocumentService } from 'global/services/document/document.service';
import { Tiers } from 'tracabilite/models/dto/tiers';
import { Gisement } from 'tracabilite/models/dto/gisement';
import { StockIntrant } from 'intrants/models/dto/stock-intrant';
import { TiersService } from 'tracabilite/services/tiers/tiers.service';
import { AnalyseIntrant } from 'intrants/models/dto/analyse-intrant';
import { AnalysesIntrantService } from 'intrants/services/analyses-intrant/analyses-intrant.service';
import { CustomDatepickerI18n } from 'global/services/custom-datepicker-i18n/custom-datepicker-i18n';
import { Site } from 'global/models/dto/site';
import { defer, pipe, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GisementsService extends CommonDatabaseQueryService {

  private _headers: any[];

  cpc = ['MS/MB', 'MO/MB', 'PM/MB stand', 'Ntot','N', "P2O5","K2O","SO3","pH","N-NH4","Densite"];

  constructor(
    _databaseService: DatabaseService,
    private analysesIntrantService: AnalysesIntrantService,
    private _documentService: DocumentService,
    private dateService: CustomDatepickerI18n,
    private translateService: TranslateService
  ) {
    super(_databaseService, {
      all: 'Gisement/all',
      fetch_all: 'Gisement/fetch_all',
      joins: 'Gisement/joins',
      document: 'Gisement/document',
      by_site : 'Gisement/by_site',
      by_site_and_codeGisement : 'Gisement/by_site_and_codeGisement',
      linked_documents: 'Gisement/linked_documents',
      appro_annuelle: 'Gisement/appro_annuelle'
    });
  }

  public get headers() {
    return [
        {nom: 'codeGisement', label: this.translateService.instant('app.global.headers.code')},
        {nom: 'nom', label: this.translateService.instant('app.global.headers.nomGisement')},
        {nom: 'destination', label: this.translateService.instant('app.global.headers.destination')},
        {nom: 'notDisplayInDropdowns', label: this.translateService.instant('app.global.headers.desactive')},
        {nom: 'producteur.nom', label: this.translateService.instant('app.global.headers.producteur')},
        {nom: 'transporteur.nom', label: this.translateService.instant('app.global.headers.transporteur')},
        {nom: 'MS/MB', label: this.translateService.instant('app.global.headers.MS/MB%')},
        {nom: 'MO/MB', label: this.translateService.instant('app.global.headers.MO/MB%')},
        {nom: 'PM/MB stand', label: this.translateService.instant('app.global.headers.PM/MB%')},
        {nom: 'Ntot', label: this.translateService.instant('app.global.headers.azoteTotale')},
        {nom: 'durable', label: this.translateService.instant('app.global.headers.durable')},
        {nom: 'justificatifDispo', label: this.translateService.instant('app.global.headers.justificatifDispo')},
    ];
  }

  /**
  * Joins
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  */
  public joins(docId: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
    return super.joins(docId, skip, limit, desc)
      .then( async (res) => {
        let gisement: Gisement;
        const gisements = new Array<Gisement>();

        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].key[1] == 0) {
            delete res.rows[i].doc.siteId;
            gisement = res.rows[i].doc as Gisement;
            gisement.analyses = [];
            if(gisement.categorie && gisement.categorie.toLowerCase() == "déchets iaa d'origine animal"){
              gisement.categorie = "Déchets IAA d'origine animale";
            }
            if(gisement.categorieRed2){
                if(gisement.categorieRed2 == "Déchet / résidus IAA"){
                    gisement.categorieRed2 = "Déchet résidus IAA";
                }
                if(gisement.categorieRed2 == "Co-produit / produit IAA"){
                    gisement.categorieRed2 = "Co-produit produit IAA";
                }
                if(gisement.categorieRed2 == "Co-produit / produit Agricole"){
                    gisement.categorieRed2 = "Co-produit produit Agricole";
                }
            }

            gisements.push(gisement);
          }
          else if(res.rows[i].key[1] == 1 && res.rows[i].value && res.rows[i].value._id && res.rows[i].value._id.startsWith("tiers"))
              gisement.transporteur = res.rows[i].doc as Tiers;
           else if(res.rows[i].key[1] == 2 && res.rows[i].value && res.rows[i].value._id && res.rows[i].value._id.startsWith("tiers"))
              gisement.negociant = res.rows[i].doc as Tiers;
           else if(res.rows[i].key[1] == 3 && res.rows[i].value && res.rows[i].value._id && res.rows[i].value._id.startsWith("tiers"))
              gisement.producteur = res.rows[i].doc as Tiers;
           else if(res.rows[i].key[1] == 4 && res.rows[i].value && res.rows[i].value._id && res.rows[i].value._id.startsWith("site"))
              gisement.site = res.rows[i].doc as Site;
           else if(res.rows[i].key[1] == 5)
              gisement.analyses.push(res.rows[i].doc as AnalyseIntrant)
        }
        if(gisement.stockIntrant) {
          try{
            gisement.stockIntrant = await this._dbService.db.get(gisement.stockIntrant);
          }catch(error){
            console.log(`attention cet stock n'existe pas ${gisement.stockIntrant}`);
          }
        }
        else if(gisement.ouvrageIncorporation) {
          try{
            gisement.ouvrageIncorporation = await this._dbService.db.get(gisement.ouvrageIncorporation);
          }catch(error){
            console.log(`attention cet ouvrage n'existe pas ${gisement.ouvrageIncorporation}`);
          }
        }
        else if(gisement.digesteur) {
          try{
            gisement.digesteur = await this._dbService.db.get(gisement.digesteur);
          }catch(error){
            console.log(`attention ce digesteur n'existe pas ${gisement.digesteur}`);
          }
        }
        gisement.parcelles = gisement.parcelles ? gisement.parcelles : [];
        gisement.analyses = _.uniqBy(gisement.analyses, '_id');
        return gisements;
    })
    .catch(error => defer(() => Promise.reject(error)));
  }

  public documents(id: string): any {
      return this._dbService.db
          .query(
              'Gisement/document',
              {
                  include_docs: true,
                  start_key: [id, 2],
                  end_key: [id, 3]
              }
          )
          .then( res => res.rows.map(d => d.doc))
          .catch( error => defer(() => Promise.reject(error)));
  }

  /**
  * Joins by site
  * @param site - site
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  * @param desc - descending sort param
  */
  public fetchAll(site: string= undefined, skip: number = 0, limit: number = 1000, desc: boolean = false): any {
    return super.fetchAll(site, skip, limit, desc)
      .then( res => {
        let gisements = new Array<Gisement>();
        gisements = res.rows.map(row => row.doc);
        return this._dbService.db.query('Site/all')
          .then(sites => {
            return this._dbService.db.query('Tiers/all')
              .then(tiers => {
                return this._dbService.db.query('StockIntrant/all')
                  .then(stocks =>{
                    return this._dbService.db.query('OuvrageIncorporation/all', { include_docs: true})
                      .then(ouvrages => {
                        return this._dbService.db.query('Digesteur/all_by_site', {include_docs: true})
                          .then(digesteurs => {
                            sites = sites.rows.map(site => site.value);
                            stocks = stocks.rows.map(stock => stock.value);
                            tiers = tiers.rows.map(t => t.value);
                            ouvrages = ouvrages.rows.map(t => t.doc);
                            digesteurs = digesteurs.rows.map(t => t.doc);
                            gisements = gisements.map(gis =>{
                              let site = sites.find(s => s._id === gis.site);
                              let producteur = tiers.find(t => t._id === gis.producteur);
                              let negociant = tiers.find(t => t._id === gis.negociant);
                              let transporteur = tiers.find(t => t._id === gis.transporteur);
                              let stock = stocks.find(s => s._id === gis.stockIntrant);
                              let ouvrage = ouvrages.find(o => o._id == gis.ouvrageIncorporation);
                              let digesteur = digesteurs.find(o => o._id == gis.digesteur);
                              if(site) {
                                gis.site = site;
                              }
                              if(producteur) {
                                gis.producteur = producteur;
                              }
                              if(transporteur) {
                                gis.transporteur = transporteur;
                              }
                              if(negociant) {
                                gis.negociant = negociant;
                              }
                              if(stock) {
                                gis.stockIntrant = stock;
                              }
                              if(ouvrage){
                                gis.ouvrageIncorporation = ouvrage;
                              }
                              if(digesteur){
                                gis.digesteur = digesteur;
                              }
                              if(gis.categorie && gis.categorie.toLowerCase() == "déchets iaa d'origine animal"){
                                gis.categorie = "Déchets IAA d'origine animale";
                              }
                              return gis;
                            })
                            if(site) gisements = gisements.filter(gis => gis.site && gis.site._id == site);
                            gisements = _.sortBy(gisements,'nom');
                            let total_rows = res.rows.length;
                            if(res.rows.length >= limit){
                              total_rows = (res.total_rows-res.offset) > limit ? res.total_rows-res.offset : limit;
                            }
                            return {rows: gisements, total_rows: total_rows};
                          })
                      })
                    })
                })
          })
      })
      .catch(error => defer(() => Promise.reject(error)));
  }

  public bySiteNoJoin(id : string){
        return super.bySite(id).then( async (res) =>  {
            let gisements = new Array<Gisement>();
            let transporteurs = new Array<Tiers>();
            let negociants = new Array<Tiers>();
            let producteurs = new Array<Tiers>();
            let sites = new Array<Site>();
            for(let i = 0; i< res.rows.length; i++){
                if(res.rows[i].key[1] == 0) gisements.push(res.rows[i].doc as Gisement);
                else if(res.rows[i].key[1] == 1) transporteurs.push(res.rows[i].doc as Tiers);
                else if(res.rows[i].key[1] == 2) negociants.push(res.rows[i].doc as Tiers);
                else if(res.rows[i].key[1] == 3) producteurs.push(res.rows[i].doc as Tiers);
                else if(res.rows[i].key[1] == 4) sites.push(res.rows[i].doc as Site);
            }
            for(let i = 0; i< gisements.length; i++){
                gisements[i].transporteur = transporteurs.find(transporteur => { return transporteur && transporteur._id == gisements[i].transporteur as any;});
                gisements[i].negociant = negociants.find(negociant => { return negociant && negociant._id == gisements[i].negociant as any;});
                gisements[i].producteur = producteurs.find(producteur => { return producteur && producteur._id == gisements[i].producteur as any;});
                gisements[i].site = sites.find(site => { return site._id == gisements[i].site as any;});
            }
            return _.sortBy(gisements, 'nom');
        })
        .catch( error => defer(() => Promise.reject(error)));
    }

  public bySite(id : string){
    return super.bySite(id).then( async (res) =>  {
      let gisements = new Array<Gisement>();
      let transporteurs = new Array<Tiers>();
      let negociants = new Array<Tiers>();
      let producteurs = new Array<Tiers>();
      let sites = new Array<Site>();
      for(let i = 0; i< res.rows.length; i++){
        if(res.rows[i].key[1] == 0) gisements.push(res.rows[i].doc as Gisement);
        else if(res.rows[i].key[1] == 1) transporteurs.push(res.rows[i].doc as Tiers);
        else if(res.rows[i].key[1] == 2) negociants.push(res.rows[i].doc as Tiers);
        else if(res.rows[i].key[1] == 3) producteurs.push(res.rows[i].doc as Tiers);
        else if(res.rows[i].key[1] == 4) sites.push(res.rows[i].doc as Site);
      }
      for(let i = 0; i< gisements.length; i++){
        gisements[i].transporteur = transporteurs.find(transporteur => { return transporteur && transporteur._id == gisements[i].transporteur as any;});
        gisements[i].negociant = negociants.find(negociant => { return negociant && negociant._id == gisements[i].negociant as any;});
        gisements[i].producteur = producteurs.find(producteur => { return producteur && producteur._id == gisements[i].producteur as any;});
        gisements[i].site = sites.find(site => { return site._id == gisements[i].site as any;});
      }
      gisements = await Promise.all(gisements.map( async(gis) => {
        try{
          if(gis.categorie && gis.categorie.toLowerCase() == "déchets iaa d'origine animal"){
            gis.categorie = "Déchets IAA d'origine animale";
          }
          if(gis.stockIntrant) gis.stockIntrant = await this._dbService.db.get(gis.stockIntrant);
          else if(gis.ouvrageIncorporation) gis.ouvrageIncorporation = await this._dbService.db.get(gis.ouvrageIncorporation);
          else if(gis.digesteur) gis.digesteur = await this._dbService.db.get(gis.digesteur);
        }catch(error){
          gis.stockIntrant = undefined;
          gis.ouvrageIncorporation =  undefined;
          gis.digesteur = undefined;
        }

        return gis;
      }))
      return _.sortBy(gisements, 'nom');
    })
    .catch( error => defer(() => Promise.reject(error)));
  }

  public list(site: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false) {
    return super.list(site, skip, limit, desc).then( async (res) => {
      let gisement: Gisement;
      let gisements = new Array<Gisement>();

      for (let i = 0; i < res.rows.length; i++) {
        if (res.rows[i] == 0) {
          delete res.rows[i].doc.siteId;
          gisement = res.rows[i].doc as Gisement;
          gisements.push(gisement);
        }
        else if(res.rows[i].key[1] == 1) gisement.transporteur = res.rows[i].doc as Tiers;
        else if(res.rows[i].key[1] == 2) gisement.negociant = res.rows[i].doc as Tiers;
        else if(res.rows[i].key[1] == 3) gisement.producteur = res.rows[i].doc as Tiers;
        else if(res.rows[i].key[1] == 4) gisement.site = res.rows[i].doc as Site;
      }
      gisements = await Promise.all(gisements.map( async(gis) => {
        if(gis.categorie && gis.categorie.toLowerCase() == "déchets iaa d'origine animal"){
          gis.categorie = "Déchets IAA d'origine animale";
        }
        if(gis.stockIntrant) gis.stockIntrant = await this._dbService.db.get(gis.stockIntrant);
        else if(gis.ouvrageIncorporation) gis.ouvrageIncorporation = await this._dbService.db.get(gis.ouvrageIncorporation);
        else if(gis.digesteur) gis.digesteur = await this._dbService.db.get(gis.digesteur);
        return gis;
      }))
      return gisements;
    })
    .catch(error => defer(() => Promise.reject(error)));
  }

  public bulkDocs(docs: any[]) {
      for (let i = 0; i < docs.length; i++) {
          const gis = new Gisement();
          _.merge(gis, docs[i]);
          docs[i] = gis.toDao();
      }
      return this._dbService.db.bulkDocs(docs);
  }



  public getIncrement() {
    const inc = this._dbService.increment('gisementsCounter');
    const gis = new Gisement();
    gis.codeGisement = 'G' + inc;
    return this.checkIfExists(gis).then(res => {
        if (!res) { return inc; } else { return this.getIncrement(); }
    }).catch(error => defer(() => Promise.reject(error)));
  }

  public create(gisement: Gisement) {
      let user = JSON.parse(sessionStorage.getItem('loggedUser'));
      gisement.createdBy = user.email;
      gisement.modifiedBy = user.email;
      gisement.createdOn = new Date().getTime();
      gisement.modifiedOn = new Date().getTime();
    if (!gisement._id) {
      gisement._id = 'gisement_' + uuid();
      return this._dbService.db.put(gisement.toDao());
    } else {
      return this.update(gisement);
    }

  }

  public update(gisement: Gisement) {
    return this._dbService.db.get(gisement._id).then(doc => {
      let user = JSON.parse(sessionStorage.getItem('loggedUser'));
      gisement.modifiedBy = user.email;
      gisement.modifiedOn = new Date().getTime();
      gisement._rev = doc._rev;
      gisement.exported = false;
      gisement.thirdPartyExport = false;
      const gisementDao = new Gisement();
      _.merge(gisementDao, gisement);
      return this._dbService.db.put(gisementDao.toDao()); });
  }

    private upSert(gisement: any) {
        if (gisement._id) { return this.update(gisement); }
        else { return this.create(gisement); }
    }

  public delete(gisement: Gisement) {
    return this._dbService.db.get(gisement._id).then(doc => {
      gisement._rev = doc._rev;
      return super.linkedDocuments(gisement._id).then(res => {
          if (res.rows.length > 0) {
              gisement.archived = true;
              return this.update(gisement);
          } else {
              return this._dbService.db.remove(gisement);
          }
      });
    });
  }

  public bulkDelete(gisements: Gisement[]) {
    let promises = [], merges = [];
    promises = gisements.map(gisement => this.delete(gisement));
    const organisation = JSON.parse(sessionStorage.getItem('organisation'));
    const document =  from(gisements).pipe(
      concatMap(gisement => this._documentService.deleteDocuments(organisation.id, gisement._id))
    ).toPromise();
    promises.unshift(document);
    return Promise.all(promises)
  }

    public bulkUpdate(gisements: Gisement[]) {
        let promises = [], merges = [];
        /*const help = gisements.map(gisement => {
            const gisementDao = new Gisement();
            _.merge(gisementDao, gisement);
            return gisementDao.toDao();
        });
        return Promise.all(gisements);*/
        promises = gisements.map(gisement => this.upSert(gisement));
        return Promise.all(promises);
    }

  public checkIfExists(gisement : Gisement){
      return this._dbService.db.query(
          'Gisement/by_codeGisement',
          {
              include_docs: true,
              key :  gisement.codeGisement
          }
      ).then(res => {
          let sameObject = false;
          if (res.rows.length == 0) { return false; } else if (gisement._id) {
              res.rows.forEach(row => {
                  if (row.id == gisement._id) {
                      sameObject = row.id == gisement._id;
                  }
              });
              return !sameObject;
          }
          return true;
      });
  }

  getApproByMonth(gisement: string, year: number, month: number) {
    return this._dbService.db.query(
      'Gisement/appro_annuelle',
      {
        include_docs: false,
        startkey: [gisement, `${year}/${month}`],
        endkey: [gisement, `${year}/${month}`],
        group_level : 2,
        reduce: true
      }
    ).then(res => {
      let value;
      res.rows[0] && res.rows[0].value ? value = res.rows[0].value : value = 0;
      return value;
    });
  }

  getApprobyYear(gisement: string, year: number) {
    const monthsRanges = this.dateService.getMonthRangesByYear(year);
    const appro = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const promises = [];
    const month1 = monthsRanges[0][0].getMonth() + 1 > 9 ? monthsRanges[0][0].getMonth() + 1 : '0' + (monthsRanges[0][0].getMonth() + 1);
    const month2 = monthsRanges[monthsRanges.length - 1][1].getMonth() + 1 > 9 ? monthsRanges[monthsRanges.length - 1][1].getMonth() + 1 : '0' + (monthsRanges[monthsRanges.length - 1][1].getMonth() + 1);
    return this._dbService.db.query(
      'Gisement/appro_annuelle',
      {
        include_docs : false,
        startkey : [gisement, `${monthsRanges[0][0].getFullYear()}/${month1}`],
        endkey : [gisement, `${monthsRanges[monthsRanges.length - 1][1].getFullYear()}/${month2}`],
        group_level : 2
      }
    ).then(res => {
      const rows = res.rows;
      for (let i = 0; i < appro.length; i++) {
        const month = monthsRanges[i][0].getMonth() + 1 > 9 ? monthsRanges[i][0].getMonth() + 1 : '0' + (monthsRanges[i][0].getMonth() + 1);
        const rowFound = rows.find(row => row.key[1] == `${monthsRanges[i][0].getFullYear()}/${month}`);
        rowFound ? appro[i] = rowFound.value : appro[i] = 0;
      }
      return appro;
    });
  }

  public get(id: string) {
    return this._dbService.db.get(id).then(res => {
      if(!res.parcelles) res.parcelles = [];
      if(res.categorie && res.categorie.toLowerCase() == "déchets iaa d'origine animal"){
        res.categorie = "Déchets IAA d'origine animale";
      }
      if(!res.parcelles) res.parcelles = [];
      return res;
    }).catch(error => defer(() => Promise.reject(error)));
  }

  asyncData<T> (data: T) {
  	return defer(() => Promise.resolve(data));
  }
}
