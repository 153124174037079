import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent {
  checked = false;
  constructor(
    private _activeModal: NgbActiveModal,
  ) { }

  close() {
    localStorage.setItem('popupNewBiogas-5', JSON.stringify(this.checked));
    this._activeModal.close(true);
  }
}
