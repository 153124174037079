import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerimetreService } from 'global/services/perimetre/perimetre.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';
import { LoginService } from 'global/services/login/login.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {BvTranslateService} from 'global/services/bv-translate/bv-translate.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupModalComponent} from 'global/modals/popup-modal/popup-modal.component';

@Component({
  selector: 'app-home-board',
  templateUrl: './home-board.component.html',
  styleUrls: ['./home-board.component.scss']
})
export class HomeBoardComponent implements OnInit, AfterViewInit {

  private perimetreSubscription;
  private perimetre;
  private parametres;
  private routes;
  private blocs;
  private displayPerformances;
  private superBlocs = [];
  private classColors = ['green', 'blue', 'black'];

  constructor(
    private route: ActivatedRoute,
    private perimetreService: PerimetreService,
    private router: Router,
    private _confirmationGuardService: ConfirmationGuardService,
    private bvTranslateService: BvTranslateService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    if(this.router.getCurrentNavigation().extras.state){
      const state =  this.router.getCurrentNavigation().extras.state;
      if(state.reload) window.location.reload();
    }
    this.getParametres();
  }

  ngOnInit() {
    this.perimetreSubscription = this.perimetreService.perimetre$.subscribe(perimetre => {
      this.perimetre = perimetre;
    });
    this.translateService.onLangChange.subscribe(lang => {
      this.getParametres(lang.lang);
    });
    this.loginService.getUser().then(user => {
      if (user) {
        this.routes = this.parametres.routes.filter(route => user.blocs.map((module: any) => module.id).includes(route.id));
        this.blocs = user.blocs;
        this.displayPerformances = this.blocs.find(bloc => bloc.id === 'performances');
        this.parametres.superBlocs.forEach(superBloc => {
          this.superBlocs.push(this.blocs.filter(bloc => {
            return superBloc.blocs.indexOf(bloc.id) >= 0;
          }));
          if (superBloc.id === 'tracabilite') {
            this.superBlocs[0].sort((a, b) => superBloc.blocs.indexOf(a.id) - superBloc.blocs.indexOf(b.id)); }
          _.remove(this.superBlocs, sB => sB.length === 0);
        });
      }
    });
  }

  ngAfterViewInit() {
    const popupNewBiogas: boolean = JSON.parse(localStorage.getItem('popupNewBiogas-5'));
    if (!popupNewBiogas) {
      this.modalService.open(PopupModalComponent, { size: 'lg' });
    }
  }

  getParametres(currentLang?: string) {
    this.parametres = this.bvTranslateService.getParametres(currentLang);
  }

  displayQantum() {
    const help = this.displayPerformances ? this.displayPerformances.modules.find(d => d.id === 'qantum') : undefined;
    return help ? help.readWrite === 1 ? true : false : false;
  }

  navigateTo(route: string) {
    const needSite = ['ration2', 'rations', 'suivi', 'journal','rondesExploit','materiel'].some((rte:string) => route.indexOf(rte) >=0);

    if (needSite && this.perimetre.dataType !== 'site') {
      this._confirmationGuardService.alert('Sélectionnez un site dans le sélecteur de périmètre');
    } else {
      this.router.navigate([route]);
    }
  }

  findRoute(id) {
    const blocModules = _.find(this.blocs,['id',id]).modules;
    const blocFirstModule = blocModules ? _.find(this.parametres.routes,['id',blocModules[0].id]).path : undefined;
    const path = _.find(this.routes, ['id', id]).path;
    return blocFirstModule ? `/${path}/${blocFirstModule}` : `/${path}`;
  }

  findLabel(id) {
    return _.find(this.parametres.blocLabels, ['id', id]).label;
  }

}
